<h2 mat-dialog-title>
  {{ dialogData.mode === 'Create' ? 'Add' : 'Update' }} Billing Address
</h2>
<form
  [formGroup]="formGroup"
  (ngSubmit)="submit(formGroup.value, dialogData, dialogRef, tab)"
>
  @if (showApiMessage) {
  <ctx-alert [alertLevel]="apiMessage.alertLevel">{{
    apiMessage.text
  }}</ctx-alert>
  }
  <mat-dialog-content>
    <ctx-tab-group #tab>
      <ctx-tab label="Address" [stepControl]="addressTab">
        <section class="flex flex-col gap-4">
          <!-- Name -->
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="name"
              matInput
              required
            />
            <mat-hint> Name of the business. </mat-hint>
            <mat-error>{{ getControlError(formGroup, 'name') }}</mat-error>
          </mat-form-field>

          <!-- Tax Identifier -->
          <mat-form-field>
            <mat-label>Tax ID</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="taxIdentifier"
              matInput
            />
            <mat-hint>
              Please enter a valid Tax ID according to your country's format.
            </mat-hint>
            <mat-error>{{
              getControlError(formGroup, 'taxIdentifier')
            }}</mat-error>
          </mat-form-field>
          <!--  Country Code -->
          <mat-form-field>
            <mat-label>Country</mat-label>
            <mat-select formControlName="countryCode" required>
              @for (country of ALL_COUNTRIES | keyvalue : originalOrder; track
              country) {
              <mat-option [value]="country.key">
                {{ country.value }}
              </mat-option>
              }
            </mat-select>
            <mat-error>{{
              getControlError(formGroup, 'countryCode')
            }}</mat-error>
          </mat-form-field>
          <!-- Address line 1 -->
          <mat-form-field>
            <mat-label>Address Line 1</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="firstLine"
              matInput
              required
            />
            <mat-error>{{ getControlError(formGroup, 'firstLine') }}</mat-error>
          </mat-form-field>
          <!-- Address line 2 -->
          <mat-form-field>
            <mat-label>Address Line 2</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="secondLine"
              matInput
            />
            <mat-error>{{
              getControlError(formGroup, 'secondLine')
            }}</mat-error>
          </mat-form-field>
          <!-- City -->
          <mat-form-field>
            <mat-label>City</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="city"
              matInput
              required
            />
            <mat-error>{{ getControlError(formGroup, 'city') }}</mat-error>
          </mat-form-field>
          <!-- Region -->
          <mat-form-field>
            <mat-label>Region</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="region"
              matInput
              required
            />
            <mat-error>{{ getControlError(formGroup, 'region') }}</mat-error>
          </mat-form-field>
          <!-- Postal code -->
          <mat-form-field>
            <mat-label>Postal code</mat-label>
            <input
              autocomplete="off"
              type="text"
              formControlName="postalCode"
              matInput
              required
            />
            <mat-error>{{
              getControlError(formGroup, 'postalCode')
            }}</mat-error>
          </mat-form-field>
        </section>
      </ctx-tab>

      <ctx-tab label="Contact" [stepControl]="contactTab">
        <!-- Contacts -->
        <div formArrayName="contacts">
          <section class="flex flex-col gap-4">
            @for (contact of contactTab.controls; let i = $index; track i;let
            last = $last) {
            <div [formGroupName]="i" class="flex flex-col gap-4">
              <mat-form-field>
                <mat-label>Name</mat-label>
                <input formControlName="name" matInput required />
                <mat-error>{{ getControlError(contact, 'name') }}</mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input type="email" formControlName="email" matInput required />
                <mat-error>{{ getControlError(contact, 'email') }}</mat-error>
              </mat-form-field>
              <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
              <button
                ctx-button--secondary
                type="button"
                color="accent"
                actionIcon="DELETE"
                (click)="removeContact(i)"
              ></button>
            </div>
            <hr />
            }
          </section>
          <span class="flex flex-col mt-2 gap-1">
            <p>You can add upto 3 contacts only.</p>
            <button
              ctx-button--secondary
              type="button"
              (click)="addContact()"
              [disabled]="this.contactTab.length === 3"
            >
              Add contact
            </button>
          </span>
        </div>
      </ctx-tab>
    </ctx-tab-group>
  </mat-dialog-content>

  <!-- Buttons -->
  <mat-dialog-actions>
    <button
      matDialogClose
      ctx-button--secondary
      [disabled]="formStates.disabled"
    >
      Close
    </button>
    <button
      ctx-button--primary
      [disabled]="formStates.disabled"
      [loading]="formStates.submit"
      type="submit"
    >
      {{ dialogData.mode === 'Create' ? 'Add' : 'Update' }}
    </button>
  </mat-dialog-actions>
</form>
